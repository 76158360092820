<template>
	<el-select
    popper-class="custom-ship-selector"
    v-model="schedulFormData.bargeName"
    style="width: 100%"
    :disabled='disabled'
    @change="$emit('handleChangeBarge',$event)"
    @visible-change="$emit('visibleChange',$event)"
    :loading="availableBargesLoading"
    placeholder="Select"
  >
    <div class="header" > <span>Name</span> <span>IMO</span> <span >SB No.</span> </div>
    <el-option
      v-for="item in availableBargesOptions"
      :key="item.id"
      :label="item.shipName"
      :value="item.id"
    >
    <div class="body">  <span>{{ item.shipName }}</span> <span>{{item.imo}}</span> <span >{{item.licenceNo}}</span> </div>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'BargeOrVesselSelector',
  props: {
    disabled:Boolean,
    schedulFormData: Object,
    availableBargesLoading: Boolean,
    availableBargesOptions: Array,
  }
}

</script>
<style lang="scss" scoped>
.header, .body {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 2fr 1fr 1fr
}
.header{
  padding:10px;
  span{
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
 }
.body{
  span{
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
  }
 }

@media screen and (max-width: 599px) {
  .header{
    span{
      font-size: 12px;
    }
  }
  .body{
      span{
        font-size: 11px;
      }
  }
}

</style>
